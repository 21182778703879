import { PageProps } from "gatsby"
import { graphql } from "gatsby"
import React from "react"
import { Seo } from "../components/Seo/Seo"
import { GameEntity } from "../entities/GameEntity"
import { Footer } from "../components/Footer/Footer"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Drawer } from "../components/Drawer/Drawer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { ArticleEntity } from "../entities/ArticleEntity"
import { Print } from "../components/pages/Print"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"

type Props = PageProps<
  {
    allGame: {
      edges: Array<{
        node: GameEntity
      }>
    }
  },
  {
    article: ArticleEntity
    games?: ArticleEntity[]
    meta: {
      indexable: boolean
    }
    langKey: string
    level: string
    isIndex: boolean
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const PrintPage: React.FC<Props> = (props) => {
  return (
    <GlobalWrapper>
      <Seo
        title={props.pageContext.article.meta.title}
        description={props.pageContext.article.meta.description}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
        index={props.pageContext.meta.indexable}
      />

      <Drawer langKey={props.pageContext.langKey} />
      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <Print
        {...props}
        level={props.pageContext.level}
        langKey={props.pageContext.langKey}
        games={props.data.allGame.edges.map(({ node }) => ({
          ...node,
        }))}
        article={props.pageContext.article}
      />

      <MobileNavbar langKey={props.pageContext.langKey} />
      <Footer lang={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default PrintPage

export const query = graphql`
  query PrintPageQuery($level: String) {
    allGame(filter: { difficulty: { eq: $level } }) {
      edges {
        node {
          id
          solution
          initial
          difficulty
        }
      }
    }
  }
`
