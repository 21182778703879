import React from "react"

type Props = {
  default: boolean
  number: number
}

const getCelClass = (props: Props) => {
  const base = `${props.default ? "" : ""}`

  if (props.default) return `${base}`

  return `${base}`
}

export const ReadOnlyCel: React.FC<Props> = (props) => {
  return (
    <div className="w-full">
      <div
        className={`relative w-full border print:border-r-2 print:border-b-2 border-l-0 border-t-0 border-slate-200`}
        style={{
          paddingTop: "100%",
        }}
      >
        <div
          className={`flex select-none sm:cursor-pointer outline-none transform text-center items-center justify-center absolute top-0 left-0 h-full w-full print:text-2xl text-3xl lg:text-3xl bg-white ${getCelClass(
            props
          )}`}
        >
          {props.number !== 0 && <div>{props.number}</div>}
          {/* {!props.number && !props.readOnly && (
            <div className="grid w-full h-full grid-cols-3">
              {props.drafts?.map((value, index) => (
                <div
                  className={`text-2xs sm:text-xs transition duration-300 ease-in-out flex items-center justify-center select-none ${
                    value ? "text-slate-500" : "text-white"
                  } `}
                  key={index}
                >
                  {value || <>&nbsp;</>}
                </div>
              ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}
