import RefreshIcon from "@heroicons/react/20/solid/ArrowPathIcon"
import PrinterIcon from "@heroicons/react/20/solid/PrinterIcon"
import React, { ReactElement, useEffect } from "react"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import {
  connector,
  ContainerProps,
} from "./containers/ControlsPrint.containers"

const Key = ({ value, active, onClick }) => (
  <div
    className={`h-20 w-20 border border-slate-100 hover:bg-blue-50 hover:text-blue-500 cursor-pointer transition-all text-slate-900 duration-300 ease-in-out rounded flex items-center justify-center text-4xl`}
    key={value}
    onClick={() => active && onClick()}
  >
    {value}
  </div>
)

type Props = {
  onClick: Function
  onDraft: Function
  onUndo: Function
  onErase: Function
  onClue: Function
  level: string
  remainingClues: number
  hideLabels?: boolean
  hideNewGame?: boolean
  showGiveUp?: boolean
  isInDraftMode: boolean
}

export const ControlsWrapper: React.FC<Props> = (props) => {
  return (
    <div className="space-y-2 block font-display">
      <div
        onClick={() => window.location.reload()}
        className="w-full py-5 font-medium flex items-center justify-center text-center uppercase transition duration-300 ease-in-out bg-white rounded cursor-pointer hover:bg-blue-50 hover:text-blue-500 border border-slate-200"
      >
        <RefreshIcon className="h-5 w-5 mr-2" />
        <FormattedMessage id="play.controls.new-game" />
      </div>

      <div
        onClick={() => window.print()}
        className="w-full py-5 font-medium text-center flex justify-center items-center text-white uppercase transition duration-300 ease-in-out bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
      >
        <PrinterIcon className="h-5 w-5 mr-2" />
        <FormattedMessage id="print.button.print" />
      </div>
    </div>
  )
}

export const ControlsContainer: React.FC<ContainerProps> = (props) => (
  <ControlsWrapper {...props} />
)

export const ControlsPrint = connector(ControlsContainer)
