import React from "react"
import { useEffect } from "react"
import { MatrixEntity } from "../../entities/GameEntity"
import { ReadOnlyCel } from "./components/ReadOnlyCel"

type Props = {
  matrix: MatrixEntity
  correction?: boolean
}

export const ReadOnlyGrid: React.FC<Props> = (props) => {
  return (
    <div className="flex justify-center font-display w-full sm:items-center">
      <div className="relative w-full">
        {props.matrix.map((cols, index) => (
          <div className={`grid relative w-full grid-cols-9`} key={index}>
            {cols.map((cel, index) => (
              <ReadOnlyCel
                key={cel.id}
                number={props.correction ? cel.solution : cel.number}
                default={false}
              />
            ))}
          </div>
        ))}

        <div
          className={`absolute inset-0 grid h-full w-full grid-cols-3 pointer-events-none`}
        >
          <div
            className="border border-t-2 border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-t-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-t-2 border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
        </div>
      </div>
    </div>
  )
}
