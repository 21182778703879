import { connect, ConnectedProps } from "react-redux"
import { ArticleEntity } from "../../../entities/ArticleEntity"
import { GameEntity, MatrixEntity } from "../../../entities/GameEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: {
    langKey: string
    level: string
    article: ArticleEntity
    games: GameEntity[]
  }
) => ({
  matrices: state.print.matrices as MatrixEntity[],
  dark: state.darkMode.dark,
  langKey: props.langKey,
  level: props.level,
  paused: state.play.paused,
  authenticated: state.auth.authenticated,
  users: state.daily.users,
  userDaily: state.daily.user,
  fetching: state.daily.fetching,
  article: props.article,
})

const mapDispatch = (
  dispatch: any,
  props: {
    langKey: string
    level: string
    article: ArticleEntity
    games: GameEntity[]
  }
) => ({
  onMount: async () => {
    await dispatch(actions.print.storeGames({ games: props.games }))
    await dispatch(actions.print.selectGame())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
