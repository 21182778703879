import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { MatrixEntity } from "../../entities/GameEntity"
import logo from "../../assets/logo/logo-with-academy.svg"
import { connector, ContainerProps } from "./containers/Print.containers"
import { BackgroundShapes } from "../BackgroundShapes"
import { useIntl } from "react-intl"
import { Navbar } from "../Navbar/Navbar"
import { getPrintUrl } from "../../i18n/utils/getUrl"
import { Article } from "../Article/Article"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { Badge } from "../Badge/Badge"
import { ControlsPrint } from "../ControlsPrint/ControlsPrint"
import { Link } from "../Link/Link"
import urls from "../../i18n/urls/levels.json"
import levels from "../../constants/levels.json"
import { ReadOnlyGrid } from "../ReadOnlyGrid/ReadOnlyGrid"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

type Props = {
  langKey: string
  level: string
  matrices: MatrixEntity[]
  onMount: Function
  article: ArticleEntity
}

export const Wrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  const intl = useIntl()

  return (
    <>
      <div className="relative overflow-hidden">
        <BackgroundShapes step={1} absolute />

        <Navbar langKey={props.langKey} level={props.level} />

        <div className="print:hidden">
          <Article
            {...props.article}
            illustration={null}
            section="print"
            hideContent
            category={null}
          />

          <ul className="flex relative items-center flex-wrap justify-center">
            {levels.map((level) => (
              <li key={level} className="mt-2 mr-2">
                <Link url={getPrintUrl(intl.locale, level)}>
                  <Badge disabled={level !== props.level} component="span">
                    {urls[intl.locale][level]}
                  </Badge>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="px-2 mt-6 print:hidden">
          <div className="container lg:flex max-w-6xl m-auto">
            <div className="container shrink-0 relative flex justify-center mx-auto lg:max-w-3xl">
              <div className="w-full">
                <div className="flex justify-center">
                  <div className="w-full">
                    {props.matrices[0] && (
                      <ReadOnlyGrid matrix={props.matrices[0]} />
                    )}
                  </div>

                  <div
                    className="flex-shrink-0 w-full hidden pl-4 sm:block"
                    style={{
                      maxWidth: "256px",
                    }}
                  >
                    <ControlsPrint level={props.level} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="print:hidden pb-10">
          <Article {...props.article} section="print" hideTitle articles={[]} />
        </div>

        <div className="hidden print:block">
          <div className="flex justify-between items-center">
            <img src={logo} className="h-10 block" />
            <Badge component="p">
              <FormattedMessage id="print.printed.grids" />
            </Badge>
          </div>

          <div className="grid grid-cols-2 mt-10 gap-4 w-full">
            {props.matrices.map((matrix, index) => (
              <ReadOnlyGrid key={index} matrix={matrix} />
            ))}
          </div>

          <div
            style={{
              breakAfter: "always",
              pageBreakAfter: "always",
            }}
          ></div>

          <div className="flex justify-between items-center">
            <img src={logo} className="h-10 block" />
            <Badge component="p">
              <FormattedMessage id="print.printed.corrections" />
            </Badge>
          </div>

          <div>
            <div className="grid grid-cols-2 gap-4 mt-10 w-full">
              {props.matrices.map((matrix, index) => (
                <ReadOnlyGrid key={index} correction matrix={matrix} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps & RouteComponentProps> = (
  props
) => <Wrapper {...props} />

export const Print = connector(Container)
